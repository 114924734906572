<template>
    <Modal :footer-actions="modalActions" ref="exportResourceDialog">
        <template #title>
            <div>{{ $l("platon.export_resource_modal_title", "Маълумотларни экспорт қилиш") }}</div>
        </template>

        <span v-if="!hasLink">No link for export. </span>

        <div v-if="hasLink">
            <span v-if="hasError" style="color: red">No link for export. </span>
            <template v-else>
                <span v-if="loading" class="spinner-loading">
                    <b-spinner style="width: 25px; height: 25px" variant="primary" />
                    {{ $l("platon.export_resource_loading", "Илтимос, кутинг, юкланмоқда ...") }}
                </span>

                <template v-else>
                    Электрон ҳужжат тайёр бўлди. Агар браузерингиз сақламаган бўлса
                    <a style="cursor: pointer" :href="downloadLink"><b>юклаб олиш</b></a>
                    тугмасини босинг ёки ҳужжатлар рўйхатга ўтиб юклаб олинг.
                </template>
            </template>
        </div>
    </Modal>
</template>

<script>
import Modal from "@Platon/components/extended/Modal.vue"
import NavigationMixin from "@Platon/mixins/NavigationMixin"

import axios from "axios"
import { API_BASE_URL } from "@Platon/const"

export default {
    name: "ExportResourceDialog",

    props: ["link", "queryParams"],

    components: { Modal },

    mixins: [NavigationMixin],

    mounted() {
        if (!this.hasLink) return
        setTimeout(this.onExportStart, 100)
    },

    data: () => ({ loading: false, interval: null, isAlternate: false, alternateLink: null }),

    computed: {
        /**
         * @return {boolean}
         */
        hasLink() {
            return this.link !== undefined && this.link !== null && this.link.length > 0
        },
        /**
         * @return {boolean}
         */
        hasError() {
            return this.error !== undefined && this.error !== null && this.error.length > 0
        },

        /**
         * @return {[{handler: default.methods.onAction, text: string}, {handler: (function(): Promise<void>), text: string}]
         * |[{handler: default.methods.onAction, text: string}]}
         */
        modalActions() {
            return this.isAlternate
                ? [
                      { text: "OK", handler: this.onAction },
                      {
                          text: this.$l("platon.export_resource_table", "Рўйҳатга ўтиш"),
                          handler: () => this.navigateTo("tables/export_resource_process")
                      }
                  ]
                : [{ text: "OK", handler: this.onAction }]
        },

        downloadLink() {
            return this.alternateLink ? this.alternateLink : this.link
        }
    },

    methods: {
        /**
         * @return {}
         */
        async onExportStart() {
            this.loading = true
            this.error = null
            try {
                let { data } = await this.$api.post(this.link)
                let { id, processType } = data
                switch (processType) {
                    case "IMMEDIATE":
                        this.isAlternate = false
                        await this.downloadResource(this.link)
                        break
                    case "ALTERNATE":
                        this.isAlternate = true
                        await this.startCheckingIsReady(id)
                        break
                }
            } catch (e) {
                this.error = e
            } finally {
                setTimeout(() => (this.loading = false), 300)
            }
        },

        closeDialog() {
            this.$refs.exportResourceDialog.hideModal()
        },

        /**
         * @return {}
         */
        onAction() {
            // alert(1);
        },

        /**
         * @return {}
         */
        downloadResource(link) {
            return new Promise(function (resolve, reject) {
                axios({
                    url: link,
                    method: "GET",
                    responseType: "blob"
                })
                    .then((response) => {
                        let { data, headers } = response
                        let headerLine = headers["Content-Disposition"]
                        if (headerLine) {
                            let startFileNameIndex = headerLine.indexOf('"') + 1
                            let endFileNameIndex = headerLine.lastIndexOf('"')
                            document
                                .createElement("a")
                                .downloadBlob(data, headerLine.substring(startFileNameIndex, endFileNameIndex))
                        } else document.createElement("a").downloadBlob(data, "loaded.xlsx")
                        resolve(data)
                    })
                    .catch(reject)
            })
        },

        /**
         * @return {}
         */
        startCheckingIsReady(id) {
            return new Promise((resolve, reject) => {
                if (this.interval) clearInterval(this.interval)
                this.interval = setInterval(() => this.processChecker(id, resolve, reject), 1000)
            })
        },

        /**
         * @return {}
         */
        processChecker(id, resolve, reject) {
            this.$api
                .get("export/process_is_ready", {
                    baseURL: API_BASE_URL,
                    params: { id }
                })
                .then(({ data }) => {
                    let { processStatus, id } = data

                    if (processStatus === "DONE") {
                        if (this.interval) clearInterval(this.interval)
                        let base = `${API_BASE_URL}export/download_process`
                        let token = `id=${id}&_bearer_token=${localStorage["auth_token"]}`

                        this.alternateLink = `${base}?${token}`

                        this.downloadResource(this.alternateLink)
                            .then(() => {
                                resolve(data)
                            })
                            .catch(reject)
                    }
                    if (["ERROR", "CANCELED"].includes(processStatus)) {
                        if (this.interval) clearInterval(this.interval)
                        resolve(data)
                    }
                })
                .catch((e) => {
                    if (this.interval) clearInterval(this.interval)
                    reject(e)
                })
        }
    }
}
</script>
<style scoped>
.spinner-loading {
    display: flex;
    align-items: center;
    gap: 5px;
}
</style>
