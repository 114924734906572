<template>
    <div id="map" style="width: 100%; border: 1px solid #ccc"></div>
</template>

<script>
import L from "leaflet/dist/leaflet"
import "leaflet/dist/leaflet.css"

import "leaflet-draw"
import "leaflet-draw/dist/leaflet.draw.css"
import { DEFAULT_MAP_CENTER } from "@Platon/const"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import LeafletDrawUz from "@Platon/external/map/DrawingToolbarUz.js"

export default {
    name: "MapPolygon",

    mixins: [InputControlMixin],

    data() {
        return {
            drawingLayer: null,
            externalPolygonLayer: null,
            map: null,
            drawing: false
        }
    },

    props: {
        edit: {
            type: Boolean,
            default: true
        },
        defaultMapCenter: {},
        defaultMapZoom: {},
        extraPolygons: {},
        extraPolygonStyle: {
            default: () => ({
                // const exampleStyle = {
                // 	weight: 2,
                // 	opacity: 1,
                // 	color: 'red',
                // 	fillOpacity: 0.7,
                // 	fillColor: 'red'
                // }

                fillColor: "rgba(255,0,0,0.35)",
                color: "#ff0000"
            })
        },
        isMultiple: {
            type: Boolean,
            default: false
        }
    },

    created() {
        L.drawLocal = LeafletDrawUz
    },

    mounted() {
        this.renderMap()
    },

    methods: {
        renderMap() {
            this.setupMap()

            if (this.edit) this.addDrawingControl()

            this.registerMapEvents()
            this.renderExternalPolygonFromJson()
            this.renderPolygonFromInput()
        },

        getMapCenter() {
            try {
                return new L.LatLng(...this.defaultMapCenter.split(","))
            } catch (e) {
                return new L.LatLng(...DEFAULT_MAP_CENTER)
            }
        },

        getMapZoom() {
            let zoom = Number(this.defaultMapZoom)

            if (Number.isInteger(zoom)) {
                return zoom
            }

            return 13
        },

        renderPolygonFromJson(geoJson) {
            let json = new L.GeoJSON(geoJson, {
                onEachFeature: (feature, layer) => {
                    layer.addTo(this.drawingLayer)
                    let seeArea = (L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]) / 10000).toFixed(2)
                    //console.log(seeArea);
                    // saveHec(seeArea);
                }
            })

            this.map.fitBounds(this.drawingLayer.getBounds())
        },

        addDrawingControl() {
            this.map.addControl(
                new L.Control.Draw({
                    edit: {
                        featureGroup: this.drawingLayer,
                        poly: {
                            allowIntersection: false
                        }
                    },
                    draw: {
                        polyline: false,
                        polygon: {
                            allowIntersection: false,
                            showArea: true,
                            drawError: {
                                color: "#b00b00",
                                timeout: 1000
                            },
                            shapeOptions: {
                                // color: '#bada55'
                            }
                        },
                        circle: false,
                        circlemarker: false,
                        marker: false,
                        rectangle: false
                    }
                })
            )
        },

        setupMap() {
            this.map = new L.Map("map", { center: this.getMapCenter(), zoom: this.getMapZoom() })
            this.drawingLayer = L.featureGroup().addTo(this.map)
            this.externalPolygonLayer = L.featureGroup().addTo(this.map)

            let googleSat = L.tileLayer("http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
                maxZoom: 20,
                subdomains: ["mt0", "mt1", "mt2", "mt3"],
                attribution: "google"
            })

            let googleStreets = L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
                maxZoom: 20,
                subdomains: ["mt0", "mt1", "mt2", "mt3"],
                attribution: "google"
            })

            let osm = L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                maxZoom: 18,
                attribution: '&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            })

            L.control
                .layers(
                    {
                        osm: osm.addTo(this.map),
                        googleSat,
                        googleStreets
                    },
                    {
                        [this.$l("platon.mappolygon.drawinglayer", "Чизмалар")]: this.drawingLayer,
                        [this.$l("platon.mappolygon.externalpolygonlayer", "Ташқи чизмалар")]: this.externalPolygonLayer
                    },
                    { position: "topright", collapsed: false }
                )
                .addTo(this.map)
        },
        getVolumeOfShapeByLatLng(latLng) {
            return +(L.GeometryUtil.geodesicArea(latLng) / 10000).toFixed(2)
        },

        registerMapEvents() {
            let map = this.map

            map.on(L.Draw.Event.CREATED, (e) => {
                if (!this.isMultiple) this.drawingLayer.clearLayers()
                let type = e.layerType
                let layer = e.layer

                this.drawingLayer.addLayer(layer)
                var seeArea = this.getVolumeOfShapeByLatLng(layer.getLatLngs()[0])
                //console.log(seeArea);
                this.saveHec(seeArea)
            })

            map.on(L.Draw.Event.DRAWSTART, (e) => {
                this.drawing = true
            })

            map.on(L.Draw.Event.DRAWSTOP, (e) => {
                this.drawing = false
                this.emitValue()
            })

            map.on(L.Draw.Event.EDITSTART, (e) => {
                this.drawing = true
            })

            map.on(L.Draw.Event.EDITSTOP, (e) => {
                this.drawing = false
                this.emitValue()
            })

            map.on(L.Draw.Event.DELETED, (e) => {
                this.drawing = false
                this.emitValue()
            })

            map.on(L.Draw.Event.EDITED, (e) => {
                var layers = e.layers
                var countOfEditedLayers = 0
                var seeArea
                layers.eachLayer((layer) => {
                    seeArea = (L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]) / 10000).toFixed(2)

                    this.saveHec(seeArea)
                    countOfEditedLayers++
                })
            })
        },

        renderPolygonFromInput() {
            if (!this.value) return

            let polygonData = Array.isArray(this.value) ? this.value : [this.value]

            let featureCollection = polygonData.map((p) => {
                return { type: "Feature", properties: {}, geometry: p }
            })

            let geoJSON = { type: "FeatureCollection", features: featureCollection }

            this.drawingLayer.clearLayers()
            this.renderPolygonFromJson(geoJSON)
        },

        emitValue() {
            let geo = this.drawingLayer.toGeoJSON()

            if (Array.isArray(geo.features) && geo.features.length > 0) {
                let geometry

                if (this.isMultiple) geometry = geo.features.map((f) => f.geometry)
                else geometry = geo.features[0].geometry

                this.$emit("input", geometry)
                this.$emit("selected-area", this.getAreaFromSelected())
            } else {
                this.$emit("input", null)
                this.$emit("selected-area", 0)
            }
        },

        saveHec(hec) {
            // console.log(`Area ${hec}`)
            // saves area
            // document.getElementById("asquare").value = hec;
        },

        getAreaFromSelected() {
            let area = 0

            this.drawingLayer.eachLayer((layer) => {
                area += this.getVolumeOfShapeByLatLng(layer.getLatLngs()[0])
            })

            return area
        },

        renderExternalPolygonFromJson() {
            const polygons = this.extraPolygons

            if (polygons == null) return

            let geoJSON = { type: "FeatureCollection", features: polygons }

            let json = new L.GeoJSON(geoJSON, {
                style: this.extraPolygonStyle,
                onEachFeature: (feature, layer) => {
                    if (feature.label) {
                        layer.bindTooltip(feature.label, { permanent: true, direction: "center" }).openTooltip()
                    }

                    layer.addTo(this.externalPolygonLayer)
                }
            })

            if (!this.value) {
                this.map.fitBounds(this.externalPolygonLayer.getBounds())
            }
        }
    },

    watch: {
        value: {
            handler() {
                if (this.map != null) {
                    this.renderPolygonFromInput()
                }
            }
        },

        extraPolygons: {
            handler() {
                if (this.map != null) {
                    this.renderExternalPolygonFromJson()
                }
            }
        },

        drawing(val) {
            this.$emit("drawing", val)
        }
    }
}
</script>

<style></style>
