export default {
	data() {
		return {
			chartOptions: {}
		}
	},

	computed: {
		chartType() {
			return this.options.type || "column"
		},

		chartData() {
			return (this.options || []).data || []
		},

		defaultOptions() {
			return {
				credits: false,
				noData: this.$l("platon.no_data", "Маълумот йўқ"),
				chart: {
					type: this.chartType
				},
				title: {
					text: this.$l(`platon.${this.options.title}`, this.options.title || "График")
				},
				subtitle: {
					text: this.options.subtitle || ""
				},
				plotOptions: {
					series: {
						stacking: this.options.stacking || null,
						dataLabels: {
							enabled: this.options.showSeriesLabels
						}
					},
					pie: {
						dataLabels: {
							enabled: this.options.showDataLabels
						},
						showInLegend: true,
						...(this.options.semi
							? {
									startAngle: -90,
									endAngle: 90,
									center: ["50%", "75%"],
									size: "110%"
							  }
							: {}),
						...(this.options.colors ? { colors: this.options.colors } : {})
					},
					line: {
						dataLabels: {
							enabled: this.options.showDataLabels
						},
						enableMouseTracking: true
					},
					areaspline: {
						fillOpacity: 0.5
					},
					...(this.options.plotOptions || {})
				},
				exporting: {
					buttons: {
						contextButton: {
							symbol: "",
							text: '<span title="Тўлиқ экранда кўриш" class="fa"></span>',
							onclick: function () {
								let options

								if (!this.fullscreen.isOpen) {
									this.oldHeight = this.renderer.height
									options = {
										tooltip: { style: { fontSize: "12pt" } },
										exporting: {
											buttons: {
												contextButton: {
													text: '<span title="Оддий режимда кўриш" class="fa"></span>'
												}
											}
										},
										chart: {
											height: null
										}
									}
								} else {
									options = {
										tooltip: { style: { fontSize: "9pt" } },
										chart: {
											height: this.oldHeight
										},
										exporting: {
											buttons: {
												contextButton: {
													text: '<span title="Тўлиқ экранда кўриш" class="fa"></span>'
												}
											}
										}
									}
								}

								this.fullscreen.toggle()
								this.update(options)
							}
						}
					}
				}
			}
		}
	}
}
