<template>
    <BaseControl v-bind="$attrs" :errors="combinedErrors">
        <b-btn @click="openPolygonControl" variant="primary" block>
            <i class="far fa-map"></i> {{ value ? "Кўриш" : "Белгилаш" }}
        </b-btn>
    </BaseControl>
</template>

<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"
import Btn from "@Platon/components/extended/Btn.vue"
import InputControlMixin from "@Platon/mixins/InputControlMixin"
import ValidationMixin from "@Platon/mixins/ValidationMixin"
import MapPolygonModal from "@Platon/components/form/controls/MapPolygonModal.vue"
import L from "leaflet/dist/leaflet"

window.AREA_MEASUREMENTS = {
    GA: 10000
}

export default {
    name: "MapPolygonControl",
    components: { Btn, BaseControl },

    mixins: [InputControlMixin, ValidationMixin],

    methods: {
        openPolygonControl() {
            this.$modal.show(
                MapPolygonModal,
                {
                    value: this.value,
                    isEditable: !this.readonly,
                    defaultMapCenter: this.item.mapCenter,
                    defaultMapZoom: this.item.mapZoom,
                    extraPolygons: this.item.extraPolygons,
                    extraPolygonStyle: this.item.extraPolygonStyle,
                    isMultiple: this.isMultiple,

                    onValue: (polygon, area) => {
                        this.$emit("input", polygon)
                        this.$parent.$emit("area-selected", area)
                        this.updateLocal(polygon)
                    }
                },
                { width: "90%", height: "90%" }
            )
        },

        /**
         * @param measurementScale Default is hector, but you can customize it, if you want in meters, send 1
         * @param precision
         * @return {string|number}
         */
        getArea(measurementScale = AREA_MEASUREMENTS.GA, precision = 2) {
            if (this.value && this.value.coordinates) {
                let totalArea = 0

                for (let coordinateArray of this.value.coordinates) {
                    const coords = this.formatCoords(coordinateArray)
                    totalArea += L.GeometryUtil.geodesicArea(coords)
                }

                if (precision > 0) return (totalArea / measurementScale).toFixed(precision)
                else return totalArea / measurementScale
            } else {
                return 0
            }
        },

        /**
         * @param {array|{lat:number,lng:number}} coords
         */
        formatCoords(coords) {
            if (coords.length > 0) {
                let first = coords[0]

                if (Array.isArray(first)) {
                    return coords.map((coord) => {
                        return {
                            lat: coord[1],
                            lng: coord[0]
                        }
                    })
                }
            }

            return coords
        }
    },
    computed: {
        isMultiple() {
            return !!this.item.isMultiple
        }
    }
}
</script>

<style></style>
