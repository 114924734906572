import HighChart from "highcharts"

export default {
	computed: {
		formattedOptions() {
			let chartTypeOptions
			switch (this.chartType) {
				case "column":
					chartTypeOptions = this.formatForColumn()
					break
				case "comparecolumn":
					chartTypeOptions = this.formatForCompare()
					break
				case "bar":
					chartTypeOptions = this.formatForBar()
					break
				case "line":
					chartTypeOptions = this.formatForLine()
					break
				case "areaspline":
					chartTypeOptions = this.formatForAreaSplin()
					break
				case "area":
					chartTypeOptions = this.formatForArea()
					break
				case "pie":
					chartTypeOptions = this.formatForPie()
					break
				case "variablepie":
					chartTypeOptions = this.formatForVariablePie()
					break
				case "tree":
					chartTypeOptions = this.formatForTree()
					break
				case "pyramid":
					chartTypeOptions = this.formatForPyramid()
					break
			}

			return HighChart.merge(
				true,
				this.defaultOptions,
				chartTypeOptions,
				this.plotOptions,
				this.options.chartConfig || {}
			)
		}
	},

	methods: {
		formatForCompare() {
			const { series, ...data } = this.formatForColumn()
			series[1].id = "main"
			series[0].linkedTo = "main"
			series[0].pointPlacement = -0.2

			series[1].dataSorting = {
				enabled: true,
				matchByName: true
			}

			return {
				chart: {
					type: "column"
				},
				series,
				...data
			}
		},
		getColor(index) {
			let colors = this.options.colors || []
			let color = colors[index] || (index && colors[index % colors.length]) || colors[0]
			return (color && { color }) || {}
		},

		getSerieType(index) {
			let series = this.options.serie_types || ["column"]
			return { type: series[index] || (index && series[series.length % index]) }
		},

		dataLayoutCreator() {
			let categories = {}
			let series = []

			let xLabels = typeof this.options.xLabel === "string" ? [this.options.xLabel] : this.options.xLabel

			this.chartData.map((dataRow, index) => {
				xLabels.forEach((label) => {
					if (!categories[label]) {
						categories[label] = []
					}

					categories[label].push(dataRow[label])
				})
				Object.entries(this.options.xPoints || {}).map(([seriesKey, seriesVal], serieIndex) => {
					if (index) {
						series.find((s) => s.key === seriesKey).data.push(dataRow[seriesKey] || 0)
					} else {
						series.push({
							key: seriesKey,
							name: this.$l(`platon.${seriesVal}`, seriesVal),
							data: [dataRow[seriesKey] || 0],
							...this.getColor(series.length),
							...this.getSerieType(serieIndex),
							stack: Math.floor(
								serieIndex / Math.ceil(Object.values(this.options.xPoints).length / xLabels.length)
							)
						})
					}
				})
			})

			return { categories, series, xLabels }
		},

		formatForColumn() {
			let { series, xLabels } = this.dataLayoutCreator()

			return {
				xAxis: xLabels.map((xl, index) => {
					return {
						id: index,
						title: {
							text: this.$l(`platon.${this.options.xTitle}`, this.options.xTitle || "")
						},
						visible: index === 0,
						crosshair: true,
						categories: this.options.chartConfig.xAxis.categories
					}
				}),

				yAxis: {
					title: {
						text: this.$l(`platon.${this.options.yTitle}`, this.options.yTitle || "")
					}
				},
				series
			}
		},

		formatForBar() {
			let { series, xLabels } = this.dataLayoutCreator()

			return {
				xAxis: xLabels.map((xl, index) => {
					return {
						categories: this.options.chartConfig.xAxis.categories
					}
				}),

				yAxis: {
					title: {
						text: this.$l(`platon.${this.options.yTitle}`, this.options.yTitle || "")
					}
				},
				series
			}
		},

		formatForLine() {
			return {
				...this.formatForColumn()
			}
		},

		formatForArea() {
			let { series, categories, xLabels } = this.dataLayoutCreator()
			series = series.map((item) => {
				return {
					...item,
					type: null
				}
			})
			return { series, categories, xLabels }
		},

		formatForAreaSplin() {
			return {
				...this.formatForBar(),
				...{
					tooltip: {
						shared: true,
						valueSuffix: this.options.suffix || ""
					}
				}
			}
		},

		formatForPie() {
			let series = []

			this.chartData.map((dataRow) => {
				for (const [seriesKey, seriesVal] of Object.entries(this.options.xPoints)) {
					series.push({
						name: this.$l(`platon.${dataRow[seriesKey]}`, dataRow[seriesKey]),
						y: dataRow[seriesVal] || 0,
						...this.getColor(series.length).color
					})
				}
			})

			return {
				accessibility: {
					point: {
						valueSuffix: this.options.suffix || ""
					}
				},
				series: [
					{
						name: this.$l(`platon.${this.options.title}`, this.options.title || "График"),
						data: series,
						...(this.options.semi ? { innerSize: "50%" } : {})
					}
				],
				tooltip: {
					pointFormat:
						this.options.format || `<b>{point.y} ${this.options.suffix}</b> ({point.percentage:.1f}%)`
				}
			}
		},

		formatForVariablePie() {
			let series = []
			let [name, y, z] = this.options.serieFields || []

			let zMin = 0
			let zMax = 0

			this.chartData.map((dataRow) => {
				series.push({
					name: this.$l(`platon.${dataRow[name]}`, dataRow[name]),
					y: dataRow[y] || 0,
					z: dataRow[z] || 0,
					...this.getColor(series.length)
				})

				if (zMin > dataRow[y]) {
					zMin = dataRow[y]
				}

				if (zMax < dataRow[y]) {
					zMax = dataRow[y]
				}
			})

			return {
				accessibility: {
					point: {
						valueSuffix: this.options.suffix || ""
					}
				},
				series: [
					{
						zMin,
						zMax,
						innerSize: "30%",
						name: this.$l(`platon.${this.options.title}`, this.options.title || "График"),
						data: series
					}
				],
				tooltip: {
					headerFormat: "",
					pointFormat:
						this.options.format || `<b>{point.y} ${this.options.suffix}</b> ({point.percentage:.1f}%)`
				}
			}
		},

		formatForPyramid() {
			let series = []
			for (const [seriesKey, seriesVal] of Object.entries(this.options.xPoints)) {
				this.chartData.map((dataRow) => {
					series.push({
						y: dataRow[seriesVal],
						name: dataRow[seriesKey],
						color: this.getColor(series.length).color
					})
				})
			}

			return {
				accessibility: {
					point: {
						valueSuffix: this.options.suffix || ""
					}
				},

				series: [
					{
						name: this.$l(`platon.${this.options.title}`, this.options.title || "График"),
						data: series
					}
				],

				tooltip: {
					pointFormat: this.options.format || `<b>{point.name} {point.y} ${this.options.suffix || ""}</b>`
				}
			}
		},

		formatForTree() {
			let series = []

			let parent = 0
			this.chartData.map((dataRow) => {
				series.push({
					name: dataRow[this.options.pointFields.title],
					...(dataRow[this.options.pointFields.parent]
						? {
								parent: dataRow[this.options.pointFields.parent].toString(),
								value: dataRow[this.options.pointFields.value]
						  }
						: {
								id: dataRow[this.options.pointFields.id].toString(),
								color: this.getColor(parent++).color
						  })
				})
			})

			return {
				accessibility: {
					point: {
						valueSuffix: this.options.suffix || ""
					}
				},
				series: [
					{
						type: "treemap",
						layoutAlgorithm: "stripes",
						alternateStartingDirection: true,

						levels: [
							{
								level: 1,
								layoutAlgorithm: "sliceAndDice",
								dataLabels: {
									enabled: true,
									align: "left",
									verticalAlign: "top"
								}
							}
						],

						data: series
					}
				],

				tooltip: {
					pointFormat: this.options.format || `<b>{point.name} {point.y} ${this.options.suffix || ""}</b>`
				}
			}
		}
	}
}
