<template>
    <div>
        <div ref="chart"></div>
    </div>
</template>

<script>
import Highcharts from "highcharts"

import ChartOptionsFormatterMixin from "@Platon/components/chart/ChartOptionsFormatterMixin"
import ChartDefaultOptionsMixin from "@Platon/components/chart/ChartDefaultOptionsMixin"

import Highcharts3D from "highcharts/highcharts-3d"
import HighchartsPie from "highcharts/modules/variable-pie"
import HighchartsFunnel from "highcharts/modules/funnel"
import HighchartsTree from "highcharts/modules/treemap"

Highcharts3D(Highcharts)
HighchartsPie(Highcharts)
HighchartsFunnel(Highcharts)
HighchartsTree(Highcharts)

export default {
    name: "platon-chart",
    mixins: [ChartDefaultOptionsMixin, ChartOptionsFormatterMixin],

    props: {
        options: {
            type: Object
        }
    },

    data() {
        return {
            chart: null
        }
    },

    methods: {
        render() {
            this.chart = new Highcharts.Chart(this.$refs.chart, this.formattedOptions)
        }
    },

    mounted() {
        this.render()
    },

    watch: {
        options() {
            this.render()
        }
    }
}
</script>
